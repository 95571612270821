import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AESEncryptDecryptService } from 'src/app/_services/aesencrypt-decrypt.service';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss'],
})
export class RoleSelectComponent implements OnInit {
  selectRoleForm: FormGroup;
  items: [];
  selectedRole: String = 'Admin';
  roleSelect: any;
  currentUser: any;
  constructor(
    private formBuilder: FormBuilder,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private router: Router
  ) {
    if (this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser'))) {
      this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
      this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
    }
  }
  getCurrentUser() {
    const currentUser = JSON.parse(
      this._AESEncryptDecryptService.decrypt(
        localStorage.getItem('currentUser')
      )
    );
    this.items = currentUser.role;
  }

  getSelectedRole(role: string) {
    // if(role === 'Waiter') {
    //   role = "Manager";
    // }
    localStorage.setItem('selectedRole', this._AESEncryptDecryptService.encrypt(role));
    // console.log(role)
    this.routeToProperComponent(role);
  }

  private routeToProperComponent(role) {
    role = role.replace(/\s/g, '');

    if (role === 'Admin') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['sidebar/admin/dashboard']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'Manager' || role === 'Server') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['sidebar/admin/waiterMenu/serverOrder']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'Table') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['/tableSetup']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'PaymentsDisplay') {
      this.router.navigate(['/paymentUser/paymentUser']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'OrdersDisplay') {
      this.router.navigate(['/orderUser/orderUser']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'Kiosk') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['/kiosk']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'QROrder') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['/QROrder']).then(() => {
        // window.location.reload();
      });
    } else if (role === 'Rewards') {
      localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
      this.router.navigate(['/rewards']).then(() => {
        // window.location.reload();
      });
    } else {
      this.router.navigate(['/login']).then(() => {});
    }
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.selectRoleForm = this.formBuilder.group({
      roleSelect: ['', Validators.required],
    });
  }
}
