import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {User} from '../_models';
import {environment} from '../../environments/environment';
import {throwError} from 'rxjs';
import {QRModel} from '../_models/qrDevice';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  getAll(id: string) {
    return this.http.get<User[]>(`${environment.apiUrl}/users/getAll/${id}`);
  }

  getById(id) {
    return this.http.get(`${environment.apiUrl}/users/${id}`);
  }

  register(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  createNewDevice(user: User) {
    return this.http.post(`${environment.apiUrl}/users/createNewDevice`, user);
  }

  accountRegistration(user: User) {
    return this.http.post(`${environment.apiUrl}/users/accountRegistration`, user);
  }

  createUser(user: User) {
    return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  update(user: User) {
    return this.http.put(`${environment.apiUrl}/users/${user._id}`, user);
  }

  updateRole(user: User) {
    return this.http.patch(`${environment.apiUrl}/users/role/${user._id}`, {role:user.role});
  }

  updateUserData(user: User) {
    return this.http.put(`${environment.apiUrl}/users/${user._id}`, user);
  }

  getOrCreateTableLogin(altHash, user?) {
    return this.http.post<User>(`${environment.apiUrl}/users/getOrCreateTableLogin`, {user, altHash});
  }

  updateAdminUserAndSettings(user: User) {
    return this.http.put(`${environment.apiUrl}/users/updateAdminAndSettings/${user._id}`, user);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`);
  }
}
