import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {forkJoin, Observable, throwError} from 'rxjs';
import { Order } from '../_models/order';
import { LineItem } from '../_models/lineItem';
import { Modification } from '../_models/modification';
import {PrinterObject} from '../_models/printerObject';
import {isNil} from "lodash";
import {AESEncryptDecryptService} from "./aesencrypt-decrypt.service";
import {Discount} from "../_models/discount";

@Injectable({ providedIn: 'root' })
export class CloverOrderService {
  constructor(private http: HttpClient,private _AESEncryptDecryptService: AESEncryptDecryptService) {
  }

  postNewOrder(order: Order): Observable<Order> {
    console.log('in CloverOrderService post New Order'); // *MES*
    const body = {
      'order': order
    };
    return this.http.post<Order>(`${environment.apiUrl}/cloverOrders/createOrder`, body);
  }

  jukeboxOrder(order: Order, playlist: any[]): Observable<any> {
    console.log('in CloverOrderService post New jukebox Order'); // *MES*
    const body = {
      'order': order,
      'playlist': playlist
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/jukeboxOrder`, body);
  }


  getOrder(id: string) {

    const body = {
      'orderId': id
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrder`, body);
  }

  getOrderHistoryById(id: string) {

    const body = {
      'orderId': id
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrderHistoryById`, body);
  }

  getOrderByDate(maxDate: Date, minDate: Date) {

    const date = new Date();
    const body = {
      'minDate': Number(minDate.getTime().toFixed(0)),
      'maxDate': Number(maxDate.getTime().toFixed(0))
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrderBetweenDatesWithLineItems`, body);
  }

  closeTableOrder(id: string, table: string) {
    const order = new Order();
    order.id = id;
    // order.title = table + ' Paid';
    order.title = table;
    order.paymentState = 'PAID';
    order.state = 'locked';

    const body = {
      'orderId': id,
      'order': order
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/closeTableOrder`, body);
  }

  updateOrder(order: Order) {

    const body = {
      'order': order
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/closeTableOrder`, body);
  }

  getUnpaidOrder(tableName: string)  {
    const body = {
      'tableName': tableName //+ '&filter=clientCreatedTime>=' + Math.floor(unixTime),
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getUnpaidOrder`, body);
  }

  getServerTableOrders(orderIds: string[], tableName?: string)  {
    const body = {
      'orderIds': orderIds,
      // 'tableName': tableName,
      // 'useTableName': false
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getServerTableOrders`, body);
  }

  moveTable(tableName: string, updatedTableName: string)  {
    const body = {
      'tableName': tableName,
      'updatedTableName': updatedTableName
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/moveTable`, body);
  }

  applyServiceChargeToOrder(orderId, serviceCharge)  {
    const body = {
      'orderId': orderId,
      'serviceCharge': serviceCharge
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/applyServiceChargeToOrder`, body);
  }

  applyServiceChargeToAllOrders(orders, serviceCharge)  {
    const body = {
      'orders': orders,
      'serviceCharge': serviceCharge
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/applyServiceChargeToMultipleOrders`, body);
  }

  getOrderLineItems(id: string) {

    const body = {
      'orderId': id
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrderLineItems`, body);
  }

  // not used as it gets all the orders and line items for a table.
  getOrdersAndLineItems(tableName: string, merchantId: string) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const cloverToken = currentUser.cloverToken;
    const body = {
      // 'merchant_id': localStorage.getItem('merchant_id'),
      'merchantId': merchantId,
      'token': cloverToken,
      'note': tableName
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/getOrdersAndLineItems`, body);
  }

  postNewLineItem(id: string, lineItem: LineItem) {

    const body = {
      'orderId': id,
      'lineItem': lineItem
    };

    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/createLineItem`, body);
  }

  fireOrder(id: string, v1?) {
    const body = {
      'printItem': new PrinterObject('PrintOrder', id),
      'orderId': id,
      'v1Print': v1 ? v1 : false
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/firePrint`, body);
  }

  payOrderOnDevice(orderId: string, total: number, deviceId: string, deviceSerial: string, firePrint: boolean) {
    const body = {
      'firePrint': firePrint,
      'total': total,
      'deviceSerial': deviceSerial,
      'deviceId': deviceId,
      'orderId': orderId
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/payOrderOnDevice`, body);
  }


  openOrderPaymentByMerchantId(id: string) {
    const body = {
      'orderId': id
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/openOrderOnStation`, body);
  }

  openOrderPaymentByDeviceId(orderId: string, deviceId: string, deviceSerial:string) {
    const body = {
      'deviceId': deviceId,
      'deviceSerial': deviceSerial,
      'orderId': orderId
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/openOrderPaymentOnDevice`, body);
  }

  deleteLineItem(id: string, lineItemId: string) {
    const body = {
      'orderId': id,
      'lineItemId': lineItemId
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/deleteLineItem`, body);
  }

  deleteOrder(orderId: string) {
    return this.http.delete<any>(`${environment.apiUrl}/cloverOrders/deleteOrder/${orderId}`);
  }

  postNewModification(id: string, lineItemID: string, modification: Modification) {
    const body = {
      'orderId': id,
      'lineitem_id': lineItemID,
      'modification': modification
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/createItemModification`, body);
  }

  postNewDiscount(order_Id, discount: Discount) {
    const body = {
      'orderId': order_Id,
      'discount': discount
    };
    return this.http.post<any>(`${environment.apiUrl}/cloverOrders/postNewDiscount`, body);
  }
}
