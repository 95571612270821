import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {Constants} from '../../../constants/constants';
import {environment} from '../../../../environments/environment';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {QRModel} from '../../../_models/qrDevice';
import {QrDeviceService} from '../../../_services/qrDevice.service';


@Component({
    selector: 'app-notification-popup',
    templateUrl: './jukebox-login-popup.html',
    styleUrls: ['./jukebox-login-popup.component.scss']
})
export class JukeboxLoginPopupComponent implements OnInit {

    description: string;
    message: string;
    currentUser: User;
    currentTableName: string;
    errorMessage: string;
    selectedRole: string;
    loading = false;
    assignedTables = false;
    inputValue = '';
    table: User;
    button_text = 'Submit';
    popupType = 'qr';
    siteURL = `https://dinengo.net`;
    siteAutoLoginURL = `https://dinengo.net/login?`;
    tablePassword = Math.random().toString(36).slice(-8);
    tableUsername = '';

    constructor(private dialogRef: MatDialogRef<JukeboxLoginPopupComponent>, private cloverOrderService: CloverOrderService,
                private alertService: AlertService, private userService: UserService, private router: Router,
                private cloverAPIService: CloverApiService, private qrService: QrDeviceService,
                private _AESEncryptDecryptService: AESEncryptDecryptService, @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
        this.button_text = data.button_text;
        this.currentTableName = data.currentTableName;
        this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
        this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
        if (!environment.production) {
            this.siteURL = `https://dine-n-go-dev.herokuapp.com`;
            this.siteAutoLoginURL = `https://dine-n-go-dev.herokuapp.com/login?`;
        }
    }

    ngOnInit() {
       this.generateQrCode(true);
    }


    generateQrCode(closeDialog?): void {
        this.loading = true;
        this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));

        const qrDevice = new User();
        qrDevice.token = this.currentUser.token;
        this.currentUser.role = [Constants.JUKEBOX];
        qrDevice.username = 'jukebox' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrDevice.orgSettings = this.currentUser.orgSettings;
        qrDevice.orgUuid = this.currentUser.orgSettings.orgUuid;
        const qrData = new QRModel();
        qrData.username = 'jukebox' + this.currentUser.orgSettings.uniqueOrgIdent;
        qrData.orgUuid = qrDevice.orgUuid;
        qrData.attachedUserId = this.currentUser._id;
        qrData.data = this._AESEncryptDecryptService.encrypt(JSON.stringify(qrDevice));

        this.tablePassword = Math.random().toString(36).slice(-8);

        this.qrService.getOrCreateJukeboxLogin(qrData, this.tablePassword).subscribe(response => {
                this.loading = false;
                this.tableUsername = response.username;
                this.siteAutoLoginURL = this.siteAutoLoginURL + 'email=' + this.tableUsername + '&password=' + this.tablePassword;

                if (closeDialog) {
                    this.popupType = 'qr';
                    // this.dialogRef.close();
                }
            },
            error => {
                throw new Error(error);
                this.loading = false;
            });
    }

    close() {
        this.dialogRef.close();
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    submit() {
        this.dialogRef.close({
            customerName: this.inputValue,
        });
    }
}
