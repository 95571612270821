import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {CartService} from '../../../_services/cart.service';
import 'rxjs-compat/add/observable/timer';
import {Observable} from 'rxjs';
import {Customer} from '../../../_models/customer';
import {CustomerRewardsService} from '../../../_services/customerRewards.service';
import {Router} from '@angular/router';
import { Constants } from 'src/app/constants/constants';
import { SquarePayServiceService } from 'src/app/_services/square-pay-service.service';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-payment-required-qr-popup.html',
  styleUrls: ['./notification-payment-required-qr-popup.scss']
})
export class NotificationPaymentRequiredQrPopupComponent implements OnInit {
  readonly Constants = Constants;
  description: string;
  message: string;
  qrUrl: string;
  orderId: string;
  currentUser: User;
  payLinkId: string;
  buttonDisabled = false;
  selectedRole: string;

  constructor(private dialogRef: MatDialogRef<NotificationPaymentRequiredQrPopupComponent>, private cloverOrderService: CloverOrderService,
              private _AESEncryptDecryptService: AESEncryptDecryptService, private rewardsService: CustomerRewardsService,
              private squarePayService: SquarePayServiceService, private cartService: CartService,
              private router: Router, @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.title;
    this.message = data.message;
    this.qrUrl = data.qrUrl;
    this.orderId = data.orderId;
    this.payLinkId = data.payLinkId;
    this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
    this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));

  }

  ngOnInit() {
  }

  closeAndCancelOrder() {
    this.buttonDisabled = true;
    this.cloverOrderService.deleteOrder(this.orderId).subscribe(() => {
      this.dialogRef.close();
    });
  }

  startOver() {
    this.buttonDisabled = true;
    this.rewardsService.customer = new Observable<Customer>();
    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([])));
    this.cartService.removeAllFromCart();
    this.cartService.removeOrderHistory();
    this.cartService.tip = 0;
    this.cloverOrderService.deleteOrder(this.orderId).subscribe(response => {
      console.log('response ' + response);
      this.dialogRef.close();
      this.router.navigate(['/kiosk']);

    }, error => {
      console.error(error);
      this.dialogRef.close();
      this.router.navigate(['/kiosk']);
    });

  }

  closeAndCancelOrderSquare() {
    if (this.currentUser.role.includes(Constants.TABLE) && this.router.url.includes('/orderHistory')) {
      this.dialogRef.close();
    } else if (this.currentUser.role.includes(Constants.KIOSK) || (this.currentUser.role.includes(Constants.TABLE) &&
        !this.router.url.includes('/orderHistory') )) {
      this.buttonDisabled = true;
      this.squarePayService.deletePaymentLink(this.payLinkId).subscribe(response => {
        console.log('Deleted Payment Link ' + response);
        this.dialogRef.close();
      }, error => {
        console.log(error);
        this.buttonDisabled = false;
      });
    } else if (this.currentUser.role.includes('Admin') || this.currentUser.role.includes(Constants.MANAGER)) {
      this.dialogRef.close();
    }
  }

  close() {
      this.dialogRef.close();
  }
}
