import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Category} from '../_models/category';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {User} from '../_models';
import {Location} from '../_models/location';
import * as uuid from 'uuid';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import { Item } from '../_models/Item';
import { Order } from '../_models/order';
import {Constants} from "../constants/constants";

@Injectable({providedIn: 'root'})
export class UberApiService {
  user: User;
  squareItems: Observable<Item[]>;
  squareCategories: Observable<Category[]>;
  CACHE_SIZE = 1;

  constructor(private http: HttpClient,
              private _AESEncryptDecryptService: AESEncryptDecryptService) {
    if (localStorage.getItem('currentUser')) {
      this.user = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
    }
  }

  Login(code: string) {
    return this.http.post<any>(`${environment.apiUrl}/uberApi/authenticate`, {code: code})
        .pipe(map(user => {
          if (user) {
            localStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(user)));
            localStorage.setItem('selectedRole', this._AESEncryptDecryptService.encrypt(user.role[0]));
          }
          return user;
        }));
  }
}
