import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Constants } from '../constants/constants';
import {User} from '../_models';
import {AESEncryptDecryptService} from "../_services/aesencrypt-decrypt.service";

@Injectable()
export class AuthGuard implements CanActivate {

  currentUser: User;
  qrDevice: User;
  constructor(private router: Router, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    if (localStorage.getItem(Constants.CURRENT_USER)) {
      this.currentUser = JSON.parse(_AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
    } else if (sessionStorage.getItem(Constants.CURRENT_USER)) {
      this.qrDevice = JSON.parse(_AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.CURRENT_USER)));
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.currentUser) {
        return true;
    } else if (this.qrDevice) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      return false;
    }
  }
}
