import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {CartService} from '../../../_services/cart.service';
import {Observable} from "rxjs";
import {takeWhile} from 'rxjs/operators';
import {CloverApiService} from "../../../_services/cloverApi.service";
import { Constants } from 'src/app/constants/constants';


@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-and-image-popup.component.html',
  styleUrls: ['./notification-and-image-popup.component.scss']
})
export class NotificationAndImagePopupComponent implements OnInit {
  readonly Constants = Constants
  description: string;
  message: string;
  imgSrc: string;
  orderId: string;
  currentUser: User;
  retryPaymentDisabled = true;
  time: number;
  orderTotal: number;
  selectedRole: string;

  constructor(private dialogRef: MatDialogRef<NotificationAndImagePopupComponent>, private cloverOrderService: CloverOrderService,
              private cloverApiService: CloverApiService, private _AESEncryptDecryptService: AESEncryptDecryptService, private cartService: CartService, @Inject(MAT_DIALOG_DATA) data) {
    this.description = data.title;
    this.message = data.message;
    this.imgSrc = data.imgSrc;
    this.orderId = data.orderId;
    this.orderTotal = data.orderTotal? data.orderTotal : 0;
    this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
    this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
  }

  ngOnInit() {
    this.disableRetryButton(21);
  }

  private disableRetryButton(seconds) {
    this.time = seconds;
    const timer = Observable.timer(0, 1000);
    timer.pipe(takeWhile(val => this.time > 0)).subscribe(t => {
      this.time--;
    });
    setTimeout(() => {
      this.retryPaymentDisabled = false;
    }, (seconds - 1) * 1000);
  }

  closeAndCancelOrder() {
    if (this.selectedRole === Constants.KIOSK) {
      this.cloverOrderService.deleteOrder(this.orderId).subscribe(response => {
        this.cloverApiService.initKioskOnDevice(this.currentUser.takePayment.cloverDeviceId,
          this.currentUser.takePayment.cloverDeviceSerial ).subscribe(() => {})
        this.dialogRef.close();
      });
   } else {
      this.dialogRef.close();
    }
  }

  resendPaymentToDevice() {
    this.retryPaymentDisabled = true;
    this.disableRetryButton(21);
    // this.resendingPaymentToDeviceAfterDelay(11);
    if(this.selectedRole === 'PaymentsDisplay'){
      this.cloverOrderService.getOrder(this.orderId).subscribe(order => {
        this.cloverOrderService.payOrderOnDevice(order.id, order.total,
          this.currentUser.takePayment.cloverDeviceId, this.currentUser.takePayment.cloverDeviceSerial, true)
          .subscribe(response => {
          });
      });
    } else {
      this.cloverOrderService.payOrderOnDevice(this.orderId, this.cartService.cloverOrder.total,
        this.currentUser.takePayment.cloverDeviceId, this.currentUser.takePayment.cloverDeviceSerial, true)
        .subscribe(response => {
        });
    }
  }

  getTimerValue() {
    if (this.time > 0) {
      return 'Resend Payment to Device ' +this.time.toString();
    } else {
      return 'Resend Payment to Device';

    }
  }
}
