export enum Constants {
  CURRENT_USER = 'currentUser',
  SELECTED_ROLE = 'selectedRole',
  SQUARE = 'Square',
  CLOVER = 'Clover',
  DINE_IN = 'Dine In',
  CARRYOUT = 'Carryout',
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  REWARDS = 'REWARDS',
  SERVER = 'Server',
  TABLE = 'Table',
  KIOSK = 'Kiosk',
  ORDERS_DISPLAY = 'OrdersDisplay',
  QR_ORDER = 'QROrder',
  JUKEBOX = 'Jukebox',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  READY = 'READY',
  DRAFT = 'DRAFT',
  MAINVIEW = 'Main',
  RECEIPT_VIEW = 'Legacy',
  CLASSIC_VIEW = 'NoNav',
  WEBSITE = 'Website',
  THEME = 'Theme',
  CUSTOMER = 'Customer',
  FANFAVORITES = 'fanFavorites',
  DELIVERY = 'Delivery',
}
