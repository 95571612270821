import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../../_models';
import {SquareApiService} from '../../../_services/square-api.service';
import * as uuid from 'uuid';
import {SquarePayServiceService} from '../../../_services/square-pay-service.service';
import {SquareMoney} from '../../../_models/squareMoney';
import {Router} from '@angular/router';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {CartServiceSquare} from "../../../../../src/app/_models/cart.service.square";
import {Constants} from "../../../constants/constants";


@Component({
  selector: 'app-notification-popup',
  templateUrl: './square-payment-popup.component.html',
  styleUrls: ['./square-payment-popup.component.scss']
})
export class SquarePaymentPopupComponent implements OnInit {

  description: string;
  message: string;
  imgSrc: string;
  orderId: string;
  currentUser: User;
  time: number;
  sourceName: string;
  selectedRole: string;
  orderTotal: SquareMoney;

  constructor(private dialogRef: MatDialogRef<SquarePaymentPopupComponent>, private cartService: CartServiceSquare,
              private squareApiService: SquareApiService, private router: Router, private squarePayService: SquarePayServiceService,
              @Inject(MAT_DIALOG_DATA) data, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.description = data.title;
    this.message = data.message;
    this.imgSrc = data.imgSrc;
    this.orderId = data.orderId;
    this.sourceName = data.sourceName;
    this.orderTotal = data.orderTotal;
    this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
    this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem("selectedRole"));
  }

  ngOnInit() {
    this.sentRequestToTerminal();
  }

  closeAndCancelOrder() {
    if (this.selectedRole === 'Table' && this.router.url.includes('/orderHistory')) {
      this.dialogRef.close();
    } else if (this.selectedRole === 'Kiosk' || (this.selectedRole === 'Table' && !this.router.url.includes('/orderHistory') )) {
      this.squareApiService.cancelOrder(this.orderId, this.currentUser.locationId).subscribe(response => {
        this.dialogRef.close();
      });
    } else if (this.selectedRole === 'Admin' || this.selectedRole === 'Manager'|| this.selectedRole === 'Server') {
      this.dialogRef.close();
    }
  }

  sentRequestToTerminal() {
    const terminalPayload = {
      idempotency_key: uuid.v4(),
      createOpenOrder: false,
      sourceName: this.sourceName,
      currency: this.currentUser.orgSettings.currency_code ? this.currentUser.orgSettings.currency_code : 'USD',
      checkout: {
        amount_money: new SquareMoney(),
        device_options: {
          collect_signature: true,
          device_id: this.currentUser.takePayment.cloverDeviceId,
          show_itemized_cart: true,
          skip_receipt_screen: false,
          tip_settings: {
            allow_tipping: true
          }
        },
        order_id: this.orderId,
        note: 'DineNGo' + this.currentUser.username,
      }
    };
    terminalPayload.checkout.amount_money = this.orderTotal;
    if (this.selectedRole !== 'Kiosk' && this.selectedRole !== Constants.QR_ORDER) {
      terminalPayload.createOpenOrder = true;
    }
    this.squarePayService.terminalPayRequest(terminalPayload).subscribe(response => {
      this.cartService.orderId = response.checkout.order_id;
    });
  }
}
