import {UserTasks} from './userTasks';
import {TakePayment} from "./takePayment";
import {OrgSetting} from "./orgSetting";

export class User {
  _id: string;
  uuid: string;
  orgUuid: string;
  username: string;
  employeeId: string;
  email: string;
  password: string;
  firstName: string;
  excludedCategories: string[];
  firstLogin: boolean;
  takePayment: TakePayment;
  assignedTables:string[];
  lastName: string;
  token: string;
  merchantId: string;
  position: {x: number, y: number};
  accountActive: boolean;
  hasCreds: boolean;
  receiptView: boolean;
  orgSettings: OrgSetting;
  role: string[];
  //square
  locationId: string;
  organization: string;
  uniqueOrgIdent: string;
  phoneNumber: number;

  //to disable dashboard
  dashboardDisabled: boolean;
}
