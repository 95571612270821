import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloverOrderService} from '../../../_services/cloverOrder.service';
import {User} from '../../../_models';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import * as _ from 'lodash';
import {AlertService, UserService} from '../../../_services';
import {Router} from '@angular/router';
import {Reservation} from '../../../_models/reservation';
import {ReservationService} from '../../../_services/reservation.service';
import {OrgTable} from '../../../_models/orgTable';
import {Constants} from '../../../constants/constants';


@Component({
    selector: 'app-notification-popup',
    templateUrl: './move-table-popup.html',
    styleUrls: ['./move-table-popup.component.scss']
})
export class MoveTablePopupComponent implements OnInit {

    description: string;
    message: string;
    orderId: string;
    isMoveTable = true;
    isEmployeeAssign = true;
    currentUser: User;
    selectedEmployee: string[] = [];
    tables: OrgTable[] = [];
    employees: User[] = [];
    currentTableName: string;
    errorMessage: string;
    selectedRole: string;
    reservation: Reservation;
    loading = false;
    tablesDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: '_id',
        textField: 'name',
        itemsShowLimit: 3,
        allowSearchFilter: true,
        enableCheckAll: false
    };
    employeesDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'username',
        textField: 'username',
        itemsShowLimit: 1,
        allowSearchFilter: true,
        enableCheckAll: true
    };
    assignedTables = false;


    constructor(private dialogRef: MatDialogRef<MoveTablePopupComponent>, private cloverOrderService: CloverOrderService, private reservationService: ReservationService,
                private alertService: AlertService, private userService: UserService, private router: Router,
                private _AESEncryptDecryptService: AESEncryptDecryptService, @Inject(MAT_DIALOG_DATA) data) {
        this.description = data.title;
        this.message = data.message;
        this.orderId = data.orderId;
        this.isMoveTable = data.isMoveTable;
        this.isEmployeeAssign = data.isEmployeeAssign;
        this.reservation = data.reservation;
        this.currentTableName = data.currentTableName;
        this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
        this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
    }

    ngOnInit() {
        if (this.currentUser.assignedTables.length > 0) {
            this.assignedTables = true;
        }
        if (this.isMoveTable) {
            this.getAllTables();
        }
        if (this.isEmployeeAssign) {
            this.getAllEmployees();
        }

    }

    getAllTables() {
        this.tables = this.currentUser.orgSettings.tables;
    }

    getAllEmployees() {
        const id = this.currentUser.orgUuid ? this.currentUser.orgUuid : this.currentUser._id;
        this.userService.getAll(id).subscribe((employees) => {
                this.employees = employees;
                this.employees = _.filter(this.employees, (o) => {
                    return (o.role.includes('Admin') || o.role.includes(Constants.MANAGER) || o.role.includes(Constants.SERVER));
                });
              this.initReservationsAssignedEmp();
            },
            error => {
                this.alertService.error(error);
            });
    }

  private initReservationsAssignedEmp() {
   this.selectedEmployee = this.reservation.assignedEmployeeUsernames;
  }

  isTableHidden(table) {
        if (this.assignedTables) {
            return this.currentUser.assignedTables.includes(table._id);
        } else {
            return true;
        }
    }

    onAssignTableSelect(item: any) {
      const selectedEmployee = this.tables.filter(emp => emp._id === item._id)[0];
        this.reservation.tableName = selectedEmployee.name;
        this.reservation.tableId = selectedEmployee._id;
    }

    onAssignTableDeSelect(item: any) {
        const selectedEmployee = this.tables.filter(emp => emp._id === item._id)[0];
        this.reservation.tableName = '';
        this.reservation.tableId = '';
        console.log('reservation', this.reservation);
    }

    onAssignEmployeeSelect(item: any) {
        const selectedEmployee = this.employees.filter(emp => emp.username === item.username)[0];
        this.selectedEmployee.push(selectedEmployee.username);
        this.reservation.assignedEmployeeIds.push(selectedEmployee._id);
        this.reservation.assignedEmployeeUsernames.push(selectedEmployee.username);
    }

    onAssignEmployeeDeSelect(item: any) {
        const selectedEmployee = this.employees.filter(emp => emp.username === item.username)[0];
        this.selectedEmployee = this.selectedEmployee.filter(emp => emp !== selectedEmployee.username);
        this.reservation.assignedEmployeeIds = this.reservation.assignedEmployeeIds.filter(empId => empId !== selectedEmployee._id);
        this.reservation.assignedEmployeeUsernames = this.reservation.assignedEmployeeUsernames.filter(username => username !== selectedEmployee.username);
        console.log('reservation', this.reservation);
    }

    close() {
        this.dialogRef.close();
    }

    moveTable() {
        this.loading = true;
        this.reservationService.update(this.reservation).subscribe(data => {
            this.close();
        });
    }
}
