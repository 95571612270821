import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../_models/index';
import {AlertService, AuthenticationService} from '../../_services';
import {AESEncryptDecryptService} from '../../_services/aesencrypt-decrypt.service';
import {Constants} from '../../constants/constants';

@Component({
  selector: 'app-logout-page',
  styleUrls: ['logout.component.scss'],
  templateUrl: 'logout.component.html'
})
export class LogoutComponent implements OnInit {
  Constants = Constants;
  logoutForm: FormGroup;
  loading = false;
  submitted = false;
  loggedOut  = false;
  currentUser: User;
  returnUrl: string;
  error = false;
  selectedRole: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private alertService: AlertService) {
    console.log('in logout.component.ts constructor');
        this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
        this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
  }

  ngOnInit() {
    console.log('in logout.component.ts OnInit');
    this.logoutForm = this.formBuilder.group({
      password: ['', Validators.required]
    });
    setTimeout(() => {
      if (!this.loggedOut) {
        if (this.selectedRole === Constants.TABLE) {
          this.router.navigate(['/sidebar/order']);
        } else if (this.selectedRole === Constants.KIOSK) {
          this.router.navigate(['/kiosk']);
        }
      }
    }, 25000);
  }

  // convenience getter for easy access to paymentForm fields
  get f() {
    return this.logoutForm.controls;
  }

  onSubmit() {
    this.error = false;
    this.submitted = true;
    this.loading = true;
    // stop here if paymentForm is invalid
    if (this.logoutForm.invalid) {
      return;
    }

    // console.log(localStorage.getItem(this.currentUser.username));
    if (this.currentUser.orgSettings.accountType === Constants.CLOVER) {
      this.cloverAuthLogout();
    } else if (this.currentUser.orgSettings.accountType === Constants.SQUARE) {
      this.squareAuthLogout();
    }
  }

  private cloverAuthLogout() {
    let username = this.currentUser.username.split(' ')[0];
    if (this.selectedRole === 'Table') {
      username = 'table' + this.currentUser.orgSettings.uniqueOrgIdent;
    }
    this.authenticationService.login(username, this.f.password.value)
        .subscribe(
            data => {
              this.authenticationService.logout();
              this.loggedOut = true;
              this.authenticationService.setUser('');
              this.router.navigate(['']);
            },
            error => {
              this.error = true;
              this.alertService.error(error);
              this.loading = false;
            });
  }

  private squareAuthLogout() {
    let username = this.currentUser.username.split(' ')[0];
    if (this.selectedRole === 'Table') {
      username = 'table' + this.currentUser.orgSettings.uniqueOrgIdent;
    }
    this.authenticationService.login(username, this.f.password.value)
        .subscribe(
            data => {
              this.authenticationService.logout();
              this.loggedOut = true;
              this.authenticationService.setUser('');
              this.router.navigate(['']);
            },
            error => {
              this.error = true;
              this.alertService.error(error);
              this.loading = false;
            });
  }
}
