import {io} from 'socket.io-client';
import {environment} from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Customer} from '../_models/customer';
import {User} from '../_models';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {Router} from '@angular/router';
import {AlertService} from './alert.service';
import {MatDialog} from '@angular/material/dialog';
import {Constants} from '../constants/constants';
import {Injectable} from '@angular/core';
import {CloverWebhook} from '../_models/cloverWebhook';

@Injectable({
    providedIn: 'root'
})
export class JukeboxSocketioService {
    socket;
    currentUser: User;
    selectedRole;
    orderId;
    merchantId;
    rewardsCustomer: Customer;
    rewardsUpdated = false;
    rewardsRedeemed = false;
    customerPlaylistQueue = [];
    videoPlaylist = [];
    orders = [];
    playlistSubject$ = new BehaviorSubject<any[]>([]);
    customerPurchasedMusic = new BehaviorSubject<boolean>(false);
    squareDeviceId: string;
    deviceAlias: any;


    constructor(private router: Router,
                private alertService: AlertService,
                private dialog: MatDialog, private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
        this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
        this.merchantId = this.currentUser.merchantId;
    }

    setupJukeboxConnection(merchantId) {
        this.socket = io(`${environment.apiServerUrl}`);
        this.customerPlaylistQueue = [];
        this.addListenerListenForJukebox(merchantId);
    }

    addListenerListenForJukebox(merchantId) {
        this.socket = io(`${environment.apiServerUrl}`);
        this.socket.on('jukebox-' + merchantId, (event: CloverWebhook[]) => {
            event.forEach(webhook => {
                this.processNewJukeboxSongRequest(webhook);
            });
            this.customerPurchasedMusic.next(true);
        });
    }

    private processNewJukeboxSongRequest(webhook) {
            if (webhook) {
                if (!this.customerPlaylistQueue) {
                    this.customerPlaylistQueue = [];
                }
                this.customerPlaylistQueue = [...this.customerPlaylistQueue, ...webhook];
                this.playlistSubject$.next(this.customerPlaylistQueue);
            }}
}
