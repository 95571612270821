import {Component, HostListener, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Modifier} from '../../../_models/modifier';
import {ModifierGroup} from '../../../_models/modifierGroup';
import { Item } from '../../../_models/Item';
import {CloverApiService} from '../../../_services/cloverApi.service';
import {AlertService} from '../../../_services';
import {User} from '../../../_models';
import {isNil} from 'lodash';
import {AESEncryptDecryptService} from '../../../_services/aesencrypt-decrypt.service';
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'app-item-options-dialog-component',
  templateUrl: './item-options-dialog-component.component.html',
  styleUrls: ['./item-options-dialog-component.component.scss'],
})
export class ItemOptionsDialogComponentComponent implements OnInit {
  Constants = Constants;
  description: string;
  item: Item;
  modifierGroups: ModifierGroup[] = [];
  modifiers: Modifier[] = [];
  selectedOptions: Modifier[] = [];
  currentUser: User;
  selectedRole: String;
  total = 0;
  selectionError = false;
  modificationCount = 0;
  modifiers_loading = true;
  firstTime = true;
  localMerchantId: any;
  storedMerchantId: string;
  storedCloverToken: any;
  smallScreen = false;

  title = '';
  quantity = 1;
  receiptViewPreferenceSet = false;
  loading = true;
  checkoutMode = false;

  constructor(private dialogRef: MatDialogRef<ItemOptionsDialogComponentComponent>, @Inject(MAT_DIALOG_DATA) data,
              private alertService: AlertService, private cloverApiService: CloverApiService,
              private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.description = data.title;

    this.item = data.options;
    this.checkoutMode = data.checkoutMode;

    this.storedMerchantId = ' ';

    if (this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER))) {
      this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
      this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
    }
    if (isNil(this.currentUser)) {
      this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.CURRENT_USER)));
      this.selectedRole = this._AESEncryptDecryptService.decrypt(sessionStorage.getItem(Constants.SELECTED_ROLE));

    }
    this.receiptViewPreferenceSet = localStorage.getItem('receiptViewSet') === 'true';
    this.smallScreen = window.innerWidth > 600 ? false : true;
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    this.smallScreen = $event.target.innerWidth > 600 ? false : true;
  }

  plus() {
    this.quantity = this.quantity + 1;
  }
  minus() {
    if (this.quantity !== 1) {
      this.quantity = this.quantity - 1;
    }

  }

  ngOnInit() {
    this.loadAllModifierGroupsByItemId(this.item.id);
    // this.modifiers_loading = false;
  }

  private loadAllModifierGroupsByItemId(itemId: string) {
    const observable = this.cloverApiService.getModifierGroupsByItemId(itemId);
    observable.subscribe((modifierG: ModifierGroup[]) => {
      Object.assign(this.modifierGroups, modifierG);
      this.loading = false;
        const scrollDiv = document.getElementsByClassName('mat-dialog-content');
        if (scrollDiv[0]) {
          scrollDiv[0].scrollTo(0, 0);
        }
        this.modifiers_loading = false;
    },
      error => {
        this.alertService.error(error);
      });
  }

  initModifierSelected() {
    if (this.modifierGroups?.length > 0) {
      this.modifierGroups.forEach((group) => {

        group.selected = 0;
      });
    }
    this.firstTime = false;
  }

  onItemSelect(index: number, modifier: Modifier, event, max) {

    if (this.firstTime) {
      this.initModifierSelected();
    }

    if (event.source.checked) {
      if (!max || this.selectedOptions.length < max) {
        this.selectedOptions.push(modifier);
        this.modifierGroups[index].selected += 1;
      } else {
        event.source.checked = false;
      }
    } else if (!event.source.checked) {
      const position = this.selectedOptions.indexOf(modifier);
      if (position !== -1) {
        this.selectedOptions.splice(position, 1);
        this.modifierGroups[index].selected -= 1;
      }
    }

  }


  private checkSelectionError(): boolean {
    this.selectionError = false;

    this.modifierGroups.forEach((group) => {

      if ((group.minRequired && group.minRequired > group.selected) ||
        (group.maxAllowed && group.maxAllowed < group.selected)) {

        this.selectionError = true;
      }
    });
    return this.selectionError;
  }


  addToCart() {
    if (this.firstTime) {
      this.initModifierSelected();
    }

    if (this.checkSelectionError()) {

      return;
    }


    const customMessage: string = (<HTMLInputElement>document.getElementById('custom-message-input')).value;


    this.dialogRef.close({
      selectedOptions: this.selectedOptions,
      note: customMessage,
      qty: this.quantity,
      checkout: false
    });

  }

  addAndRouteToCart() {
    if (this.firstTime) {
      this.initModifierSelected();
    }

    if (this.checkSelectionError()) {

      return;
    }


    const customMessage: string = (<HTMLInputElement>document.getElementById('custom-message-input')).value;


    this.dialogRef.close({
      selectedOptions: this.selectedOptions,
      note: customMessage,
      qty: this.quantity,
      checkout: true
    });

  }

  close() {
    this.dialogRef.close();
  }

  private performUpdateSelected(isChecked: boolean, item: ModifierGroup, modifier: Modifier) {
    if (isChecked) {
      item.selected++;
      this.modificationCount++;
      modifier.checked = true;
      this.selectedOptions.push(modifier);
      this.total += Number(modifier.price);
    } else {

      item.selected--;
      this.modificationCount--;
      modifier.checked = false;
      const index = this.selectedOptions.indexOf(modifier);
      if (index !== -1) {
        this.selectedOptions.splice(index, 1);
        this.total -= Number(modifier.price);
      }
    }
  }
}
