import { Component, Input, OnInit } from '@angular/core';
import {User} from "../../../_models";
import {environment} from "../../../../environments/environment";
import {AESEncryptDecryptService} from "../../../_services/aesencrypt-decrypt.service";
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.scss']
})
export class QrGeneratorComponent implements OnInit {

  currentUser: User;
  @Input() qrUrl :string = '';
  @Input() title :string = '';
  @Input() imageSrc :string = '';
  @Input() height :number = 75;
  @Input() width :number = 75;
  @Input() colorLight :string = '#ffffffff';
  @Input() colorDark :string = '#000000ff';


  constructor(private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
  }

  ngOnInit(): void {
  }

}
