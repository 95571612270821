import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Category} from '../_models/category';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {User} from '../_models';
import {Location} from '../_models/location';
import * as uuid from 'uuid';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import { Item } from '../_models/Item';
import { Order } from '../_models/order';

@Injectable({providedIn: 'root'})
export class SquareApiService {
  user: User;
  squareItems: Observable<Item[]>;
  squareCategories: Observable<Category[]>;
  CACHE_SIZE = 1;

  constructor(private http: HttpClient,
              private _AESEncryptDecryptService: AESEncryptDecryptService) {
    if (localStorage.getItem('currentUser')) {
      this.user = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
    }
  }

  getAllCategories() {
    if (!this.squareCategories) {
      this.squareCategories =
        this.http.get<Category[]>(`${environment.apiUrl}/squareApi/categoriesWithImages`)
          .pipe(shareReplay(this.CACHE_SIZE));
    }
    return this.squareCategories;
  }

  getAllItems() {
    if (!this.squareItems) {
      this.squareItems = this.http.get<Item[]>(`${environment.apiUrl}/squareApi/itemsWithImages`)
        .pipe(shareReplay(this.CACHE_SIZE));
    }
    return this.squareItems;
  }

  getAllItemsByCategoryId(id: string) {
    return this.http.get<any>(`${environment.apiUrl}/squareApi/itemsByCategoryWithImages/${id}`);
  }

  getModifierLists(item: Item) {
    const body = {
      'item': item
    };
    return this.http.post<any>(`${environment.apiUrl}/squareApi/modifierLists`, body);
  }

  getMerchantLocations() {
    return this.http.get<Location[]>(`${environment.apiUrl}/squareApi/locations`);
  }

  calculateOrder(order: Order) {
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order/calculate`, {order});
  }

  createOrder(order: Order) {
    order.source.name = localStorage.getItem('orderType') ? localStorage.getItem('orderType') : 'DineNGo';
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order`, {order});
  }

  getAllOrders(location_ids: string[], states?: string[], cursor?, limit?) {
   let statesToPass = ['OPEN', 'DRAFT', 'COMPLETED', 'CANCELED'];
    const nowDate = new Date();
    let oneDayAgo = new Date(nowDate.setDate(nowDate.getDate()-1));


   if(states.length > 0) {
     statesToPass = states;
   }
    let body = {
      query: {
        filter: {
          state_filter: {
            states: statesToPass
          },
        },
        sort: {
          sort_field: "UPDATED_AT",
          sort_order: "DESC"
        }
      },
      location_ids: location_ids,
      cursor: cursor,
      limit: limit ? limit : 100
    }
    return this.http.post<Order[]>(`${environment.apiUrl}/squareApi/order/getAllOrders`, body);
  }

  getOrderById(id:string) {
    return this.http.get<Order>(`${environment.apiUrl}/squareApi/getOrderById/` + id);
  }

  deleteOpenOrder() {
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order/delete`, {username: this.user.username});
  }

  getOpenOrderForTable(username) {
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order/open`, {username: username});
  }

  getOrderByDate(maxDate: Date, minDate: Date, orderIds: string[]) {
    const date = new Date();
    const squareData = {
      locationIds: orderIds,
      returnEntries: true
    };
    const body = {
      'squareData': squareData,
      'minDate': Number(minDate.getTime().toFixed(0)),
      'maxDate': maxDate.getTime() === 0 ? date.getTime() : maxDate.getTime()
    };
    return this.http.post<any>(`${environment.apiUrl}/squareApi/getOrderBetweenDatesWithLineItems`, body);
  }

  updateOrder(order: Order) {
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order/update`, {order: order});
  }
  
  removeLineItemFromOrder(order: Order) {
    return this.http.post<Order>(`${environment.apiUrl}/squareApi/order/removeLineItem`, {order: order});
  }

  getOrdersListByIds(orderIds: string[]) {
    return this.http.post<Order[]>(`${environment.apiUrl}/squareApi/order/getByOrderIds`, {order_ids: orderIds});
  }

  cancelOrder(orderId: string, locationId: string, version?:number) {
    const body = {
      order: {
        state: 'CANCELED',
        location_id: locationId,
        order_id: orderId,
        version: version,
      },
      idempotency_key: uuid.v4()
    };
    return this.http.put<any>(`${environment.apiUrl}/squareApi/cancelOrder`, body );
  }

  createDeviceCode(body) {
    return this.http.post<any>(`${environment.apiUrl}/squareApi/createDeviceCode`, body);
  }
}
