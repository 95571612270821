import { Component, OnInit } from '@angular/core';
import { AlertService, AuthenticationService } from './_services';
import { User } from './_models';
import {NavigationExtras, NavigationStart, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AESEncryptDecryptService } from "./_services/aesencrypt-decrypt.service";
import { Constants } from './constants/constants';
import {NotifierService} from 'angular-notifier';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  navbar = false;
  currentUser: User;
  showMenu: boolean;
  loginPage: boolean;
  registerPage: boolean;
  apiUrl: string = environment.apiUrl;
  currentRole: string;

  constructor(private authService: AuthenticationService,
    private alertService: AlertService, private notifier: NotifierService, private _AESEncryptDecryptService: AESEncryptDecryptService, private router: Router, private translate: TranslateService) {
    if (localStorage.getItem(Constants.CURRENT_USER)) {
      this.currentUser = JSON.parse(_AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
      this.currentRole = _AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.SELECTED_ROLE));
      // console.log(this.currentUser, 'this.currentUserthis.currentUserthis.currentUser')
      translate.setDefaultLang('English');
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          // console.log('each event url :' + event.url);
          if (_AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER))) {
            this.currentUser = JSON.parse(_AESEncryptDecryptService.decrypt(localStorage.getItem(Constants.CURRENT_USER)));
          }
          this.showMenu = (this.currentUser ? this.currentRole !== Constants.KIOSK : false);
          this.loginPage = event.url.includes('/login');
          this.registerPage = event.url.includes('/register');
        }
      });
      this.authService.getLoggedInSubject().subscribe(response => {
        this.navbar = response;
      },
        error => {
          this.alertService.error(error);
        });
    } else {
      let navigationExtras: NavigationExtras = {
        queryParamsHandling: 'preserve',
        preserveFragment: true
      };
      if (!window.location.href.includes('/login') && !window.location.href.includes('/cloverAuth') && !window.location.href.includes('/receipt') && !window.location.href.includes('/register') && !window.location.href.includes('customer/signup') && !window.location.href.includes('/youtube') && !window.location.href.includes('/order')) {
        this.router.navigate([''], {queryParamsHandling: 'preserve'});
      }

    }
  }

  ngOnInit() {
    // console.log('app.component.ts OnInit');
  }
}
