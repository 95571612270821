import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {LineItem} from '../_models/lineItem';
import {CloverApiService} from './cloverApi.service';
import {CloverOrderService} from './cloverOrder.service';
import {AlertService} from './index';
import {AESEncryptDecryptService} from './aesencrypt-decrypt.service';
import {CartItem} from '../_models/cartItem';
import {Order} from '../_models/order';

@Injectable()
export class CartService {
    private lineItemsInCartSubject: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
    private lineItemsInCart: LineItem[] = [];
    private lineItemOrderHistorySubject: BehaviorSubject<LineItem[]> = new BehaviorSubject([]);
    private lineItemOrderHistory: LineItem[] = [];
    private taxRate = 0;
    private taxAmount = 0;
    private defaultTax: any;
    cloverOrder: any;
    tip = 0;

    // square
    private squareLineItemsInCartSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
    private squareLineItemsInCart: CartItem[] = [];
    orderId: string; // used for terminal pay link id
    oldTableOrder: Order; // used for table previous order.
    orderInFocus: Order;

    constructor(private cloverApiService: CloverApiService, private _AESEncryptDecryptService: AESEncryptDecryptService, private cloverOrderService: CloverOrderService,
                private alertService: AlertService) {
        if (localStorage.getItem('currentUser')) {
            const currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));

            if (currentUser.orgSettings?.accountType === 'Clover') {
                this.cloverApiService.getTaxRate().subscribe((taxRate) => {
                    this.defaultTax = taxRate;
                    this.taxRate = (Number(taxRate.rate) / 100000);
                });
            }
        }
        this.lineItemsInCartSubject.subscribe(_ => this.lineItemsInCart = _);
    }

    public setCloverOrder(orderId: string) {
        this.cloverOrderService.getOrder(orderId).subscribe(response => {
                this.cloverOrder = response;
                console.log('got order ' + response);
            },
            error => {
                this.alertService.error(error);
            });
    }

    getLineItemsInCart() {
        return this.lineItemsInCartSubject;
    }

    public addToCart(lineItem: LineItem) {
        let NewItem: any;
        let storageCart: any;
        try {
            if (localStorage.getItem('CartItem')) {
                // tslint:disable-next-line:prefer-const
                storageCart = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
            }
            if (storageCart?.length > 0) {
                let itemFound = false;
                for (let i = 0; i < storageCart.length; i++) {
                    if (storageCart[i].item.id === lineItem.item?.id) {
                        if (storageCart[i].newModifications.length === lineItem.newModifications.length) {
                            let allItemsMatched = true;
                            for (let j = 0; j < storageCart[i].newModifications.length; j++) {
                                let matched = false;
                                for (let k = 0; k < lineItem.newModifications.length; k++) {
                                    console.log('kk', lineItem.newModifications[k].modifier);
                                    if (storageCart[i].newModifications[j].modifier.id == lineItem.newModifications[k].modifier.id) {
                                        matched = true;
                                        break;
                                    }
                                }
                                if (!matched) {
                                    allItemsMatched = false;
                                    break;
                                }
                            }
                            if (allItemsMatched && (lineItem.note === storageCart[i].note)) {
                                console.log(storageCart[i], lineItem);
                                lineItem.quantity = lineItem.quantity + storageCart[i].quantity;
                                storageCart.splice(i, 1, lineItem);
                                localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify(storageCart)));
                                this.lineItemsInCartSubject.next(storageCart);
                                itemFound = true;
                                break;
                            }
                        }

                    }
                }

                if (!itemFound) {
                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([...storageCart, lineItem])));
                    this.lineItemsInCartSubject.next(JSON.parse(JSON.stringify([...this.lineItemsInCart, lineItem])));
                }
            } else {
                localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([...this.lineItemsInCart, lineItem])));
                this.lineItemsInCartSubject.next(JSON.parse(JSON.stringify([...this.lineItemsInCart, lineItem])));
                return;
            }
        } catch (err) {
        }

    }

   public isItemInCart(lineItem: any) {
        let storageCart;
        let itemFound;
        const items = [];
        if (localStorage.getItem('CartItem')) {
            // tslint:disable-next-line:prefer-const
            storageCart = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
        }
        if (storageCart?.length > 0) {
            for (let i = 0; i < storageCart.length; i++) {
                if (storageCart[i].item?.id === lineItem?.id) {
                    items.push(lineItem);
                    itemFound =  storageCart[i];
                }
            }
            return itemFound;
        }
    }

    public getItemCountInCart(lineItem: any) {
        let storageCart;
        let itemCount = 0;
        if (localStorage.getItem('CartItem')) {
            // tslint:disable-next-line:prefer-const
            storageCart = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
        }
        if (storageCart?.length > 0) {
            for (let i = 0; i < storageCart.length; i++) {
                if (storageCart[i].item?.id === lineItem.id) {
                    itemCount = itemCount + storageCart[i].quantity;
                }
            }
            return itemCount;
        }
    }

    public getCartItems() {
        if (localStorage.getItem('CartItem')) {
            return JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
        }
    }

    public removeFromCart(lineItem: LineItem) {
        let NewItem: any;
        let storageCart: any;
        try {
            if (localStorage.getItem('CartItem')) {
                // tslint:disable-next-line:prefer-const
                storageCart = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
            }
            if (storageCart?.length > 0) {
                let itemFound = false;
                for (let i = 0; i < storageCart.length; i++) {
                    if (storageCart[i].item.id === lineItem.item.id) {
                        if (storageCart[i].newModifications.length === lineItem.newModifications.length) {
                            let allItemsMatched = true;
                            for (let j = 0; j < storageCart[i].newModifications.length; j++) {
                                let matched = false;
                                for (let k = 0; k < lineItem.newModifications.length; k++) {
                                    console.log('kk', lineItem.newModifications[k].modifier);
                                    if (storageCart[i].newModifications[j].modifier.id === lineItem.newModifications[k].modifier.id) {
                                        matched = true;
                                        break;
                                    }
                                }
                                if (!matched) {
                                    allItemsMatched = false;
                                    break;
                                }
                            }
                            if (allItemsMatched && (storageCart[i].note === lineItem.note)) {
                                console.log(storageCart[i], lineItem);
                                storageCart[i].quantity = storageCart[i].quantity ? storageCart[i].quantity -= 1 : lineItem.quantity;
                                if (storageCart[i].quantity < 1) {
                                    this.deletecart(lineItem, i);
                                    storageCart.splice(i, 1);
                                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([...storageCart])));
                                    this.lineItemsInCartSubject.next([...storageCart]);
                                    itemFound = true;
                                    break;
                                } else {
                                    // storageCart.splice(i, 1);
                                    storageCart.splice(i, 1, lineItem);
                                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify(storageCart)));
                                    this.lineItemsInCartSubject.next(storageCart);
                                    itemFound = true;
                                    break;
                                }
                            }
                        }

                    }
                }

                if (!itemFound) {
                    localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([...storageCart, lineItem])));
                    this.lineItemsInCartSubject.next(JSON.parse(JSON.stringify([...this.lineItemsInCart, lineItem])));
                }
            } else {
                localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify([...this.lineItemsInCart, lineItem])));
                this.lineItemsInCartSubject.next(JSON.parse(JSON.stringify([...this.lineItemsInCart, lineItem])));
                return;
            }
        } catch (err) {
        }

    }

    public deletecart(lineItem: LineItem, j) {
        const ad = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('CartItem')));
        if (ad[j].cartId === lineItem.cartId) {
            ad.splice(j, 1);
        }
        localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify(ad)));
        this.lineItemsInCartSubject.next(ad);
    }

    public addToOrderHistorynew(lineitem) {
        // JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.setItem('CartItem',lineitem)));
        localStorage.setItem('CartItem', this._AESEncryptDecryptService.encrypt(JSON.stringify(lineitem)));
        console.log('=========lineitem=========', lineitem);
        // console.log('in cart service');
        // this.lineItemOrderHistory.push(lineItem);
        // this.lineItemOrderHistorySubject.next([...this.lineItemOrderHistory]);
    }


    public addToOrderHistory(lineItem: any) {
        // console.log('in cart service');
        this.lineItemOrderHistory.push(lineItem);
        this.lineItemOrderHistorySubject.next([...this.lineItemOrderHistory]);
    }

    public getItems(): Observable<LineItem[]> {
        return this.lineItemsInCartSubject;
    }

    public updateCart(updated) {
        const currentItems = [...this.lineItemsInCart];
        currentItems.push(updated);

        this.lineItemsInCartSubject.next(currentItems);
    }

    public removeAllFromCart() {
        this.lineItemsInCart = [];
        this.lineItemsInCartSubject.next([...this.lineItemsInCart]);
    }

    public removeOrderHistory() {
        this.lineItemOrderHistory = [];
        this.lineItemOrderHistorySubject.next([...this.lineItemsInCart]);
    }

    public getTotalAmount(withTax?) {
        let total = 0;
        let lineItemList: LineItem[] = [];
        this.getItems().subscribe(lineItem => {
                lineItemList = lineItem;
            },
            error => {
                this.alertService.error(error);
            });
        lineItemList.forEach(lineItem => {
            if (lineItem.priceWithModifiers > 0) {
                if (lineItem?.quantity) {
                    total += (lineItem.priceWithModifiers) * (lineItem?.quantity);
                } else {
                    total += lineItem.priceWithModifiers;
                }
            } else {
                if (lineItem?.quantity) {
                    total += (lineItem?.price) * (lineItem?.quantity);
                } else {
                    total += lineItem?.price;
                }
            }
        });

        if (withTax) {
            this.setTaxAmount();
            return total + this.taxAmount;
        } else if (!withTax) {
            return total;
        }
    }

    public getTaxAmount() {
        this.setTaxAmount();
        return this.taxAmount;
    }

    public getTaxRate() {
        return this.taxRate;
    }

    public getHistoryTaxAmount() {
        this.setHistoryTaxAmount();
        return this.taxAmount;
    }

    public setTaxAmount() {
        // const taxRatePercent =(this.taxRate/100000);
        // console.log('tax rate percent: ' +taxRatePercent);
        this.taxAmount = 0;
        const taxRate = (this.taxRate / 100);
        // console.log('tax rate percent: ' + taxRate);
        const total = Math.ceil(this.getTotalAmount());
        this.taxAmount = total * taxRate + 1;
        // console.log('tax amount percent: ' + this.taxAmount);

    }

    public setTaxAmountnew() {
        // const taxRatePercent =(this.taxRate/100000);
        // console.log('tax rate percent: ' +taxRatePercent);
        this.taxAmount = 0;
        const taxRate = (this.taxRate / 100);
        // console.log('tax rate percent: ' + taxRate);
        const total = Math.ceil(this.getTotalAmount());
        console.log('tax amount percent: ' + this.taxAmount);
        return this.taxAmount = total * taxRate + 1;

    }

    public SetOrderHistoryToCart() {
        this.lineItemOrderHistory = this.lineItemsInCart;
        this.lineItemOrderHistorySubject.next([...this.lineItemOrderHistory]);

    }

    public getOrderHistoryItems(): Observable<LineItem[]> {
        return this.lineItemOrderHistorySubject;
    }

    public getOrderHistoryTotalAmount(withTax?) {
        let total = 0;
        const lineItemList: LineItem[] = [];
        this.getOrderHistoryItems().pipe(first()).subscribe(lineItems => {
                lineItemList.push.apply(lineItemList, lineItems[0]);
                lineItemList.forEach(lineItem => {
                    total += lineItem.price;
                    lineItem.modifications?.forEach(modification => {
                        total += modification.amount;
                    });
                });
            },
            error => {
                this.alertService.error(error);
            });
        if (withTax) {
            this.setHistoryTaxAmount();
            return total + this.taxAmount;
        } else if (!withTax) {
            return total;
        }
    }

    public setHistoryTaxAmount() {
        this.taxAmount = 0;
        const taxRate = (this.taxRate / 100);
        const total = Math.ceil(this.getOrderHistoryTotalAmount()) + 1;
        this.taxAmount = total * taxRate;
    }


    // square

    public addToCartSquare(cartItem: CartItem) {
        this.squareLineItemsInCartSubject.next([...this.squareLineItemsInCart, cartItem]);
    }

    public cloneItemSquare(uid: string) {
        const cartItem: CartItem = this.squareLineItemsInCart.find(li => li.uid === uid);
        this.addToCartSquare(new CartItem(cartItem.item, cartItem.selectedModifiers, cartItem.note, cartItem.quantity));
    }

    public getItemsSquare(): Observable<CartItem[]> {
        return this.squareLineItemsInCartSubject;
    }

    public removeFromCartSquare(uid: string) {
        this.squareLineItemsInCartSubject.next(this.squareLineItemsInCart.filter(_ => _.uid !== uid));
    }

    public removeAllFromCartSquare() {
        this.squareLineItemsInCart = [];
        this.squareLineItemsInCartSubject.next([...this.squareLineItemsInCart]);
    }

}

