import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {User} from '../_models';
import {Image} from '../_models/image';
import {CloverCategories} from '../_models/cloverCategories';
import {Item} from '../_models/Item';
import {AlertService, AuthenticationService, UserService} from '../_services';
import {ActivatedRoute, Router} from '@angular/router';
import {ServerRequestService} from '../_services/serverRequest.service';
import {ImageService} from '../_services/image.service';
import {NotifierService} from 'angular-notifier';
import {AESEncryptDecryptService} from '../_services/aesencrypt-decrypt.service';
import {QrDeviceService} from '../_services/qrDevice.service';
import {QRModel} from '../_models/qrDevice';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Constants} from '../constants/constants';
import { Location } from '@angular/common';

@Component({
  selector: 'app-clover-auth-router',
  templateUrl: './clover-auth-router.component.html',
  styleUrls: ['./clover-auth-router.component.css']
})
export class CloverAuthRouterComponent implements OnInit {

  currentUser: User;
  // categories: Category[] = [];
  images: Image[] = [];
  loading = true;
  tableUuid: string;
  serverButtonDisabled = true;
  tableName: string;
  url_merchant_id: string;
  localMerchantId: any;
  storedMerchantId: string;
  storedCloverToken: string;
  categoriesFirstMerchants: string[];
  categoriesFirstSwitch: boolean;
  cloverCategories: CloverCategories[] = [];
  cloverItems: Item[] = [];
  url_client_id: string;
  url_code: string;
  qrDevice: User;
  categoryUuid: string;
  selectedRole: string;
  loadingButton = false;
  private qrUsername: string;
  private isQrLogin: boolean;
  private orderId: string;
  private playlist: any[];
  private website = false;
  private userInReq: User;

  constructor(private alertService: AlertService, private activatedRoute: ActivatedRoute,
              private serverRequestService: ServerRequestService,
              private imageService: ImageService,
              private userService: UserService,
              private _AESEncryptDecryptService: AESEncryptDecryptService,
              private router: Router,
              private location: Location,
              private authenticationService: AuthenticationService,
              private deviceService: DeviceDetectorService,
              private qrService: QrDeviceService,
              private notifier: NotifierService) {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(localStorage.getItem('currentUser')));
    }
    if (localStorage.getItem('selectedRole')) {
      this.selectedRole = this._AESEncryptDecryptService.decrypt(localStorage.getItem('selectedRole'));
    }
  }

  ngOnInit() {

    const url_string = window.location.href;
    const url = new URL(url_string);

    this.routerPropertiesSetup();
    if (this.isQrLogin) {
      this.qrService.getByUsername(this.qrUsername).subscribe((qrUser: QRModel) => {
          this.qrDevice = new User();
          this.qrDevice.takePayment = qrUser.takePayment;
          this.qrDevice.merchantId = qrUser.merchantId;
          this.qrDevice.orgUuid = qrUser.orgUuid;
          this.qrDevice.excludedCategories = qrUser.excludedCategories;
          this.qrDevice.firstName = 'QR ';
          this.qrDevice.token = qrUser.token;
          this.qrDevice.orgSettings = qrUser.orgSettings;
          this.qrDevice.role = [qrUser.role ? qrUser.role : Constants.QR_ORDER];
          this.selectedRole = qrUser.role ? qrUser.role : Constants.QR_ORDER;
          if (this.website) {
            this.qrDevice.role = [Constants.WEBSITE];
            this.selectedRole = Constants.WEBSITE;
            this.qrDevice.takePayment.devicePaymentEnabled = true;
            this.qrDevice.takePayment.scanToPayPayment = false;
            this.qrDevice.takePayment.cloverConnectorPayment = false;
            this.qrDevice.firstName =  'Website Order ';
            this.qrDevice.takePayment.creditCardPayment = true;
          }
          sessionStorage.setItem('currentUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(this.qrDevice)));
          sessionStorage.setItem('selectedRole', this._AESEncryptDecryptService.encrypt(this.selectedRole));
          sessionStorage.setItem('', ' ');
          this.authenticationService.setUser(this.qrDevice);

          if (this.orderId) {
            this.currentUser = this.qrDevice;
            this.scanToPayRouter(this.orderId, this.currentUser).then(r => r);
            return;
          } else if (this.playlist) {
            this.currentUser = this.qrDevice;
            this.scanToJukeboxPay(this.playlist, this.currentUser).then(r => r);
          } else if (this.website) {
            console.log('Welcome to our website');
            this.router.navigate(['/order']).then(() => {
              window.location.reload();
            });
          } else {
            this.router.navigate(['/kiosk']).then(() => {
                window.location.reload();
            });
          }
          return;
        },
      error => {
        console.error(error);
        throw new Error(error);
      });
    } else if (this.userInReq) {
      this.processLoggedInUrlUser(url);
    } else if (this.currentUser && !_.isNil(this.currentUser?.username) && this.currentUser?.hasCreds) {
      this.activatedRoute.params.subscribe(params => {
          this.tableUuid = params['tableUuid'] ? params['tableUuid'] : this.currentUser.username;
          this.tableName = params['tableName'] ? params['tableName'] : this.currentUser.username;
        },
        error => {
          this.alertService.error(error);
        });
      this.categoriesFirstMerchants = ['invalid id'];
      this.categoriesFirstSwitch = true;
      this.routeToProperComponent();
    } else if (this.url_merchant_id &&  this.url_client_id && this.url_code && !this.currentUser.hasCreds) {
      this.performCloverAuthentication();
    } else {
      this.router.navigate([''], {queryParamsHandling: 'preserve'});
    }
  }

  private routerPropertiesSetup() {
    const url_string = window.location.href;
    const url = new URL(url_string);

    if (url.searchParams.get('qr')) {
      this.isQrLogin = true;
      this.qrUsername = this._AESEncryptDecryptService.decrypt(url.searchParams.get('qr'));
    }
    if (url.searchParams.get('orderId')) {
      this.orderId = url.searchParams.get('orderId');
    }
    if (url.searchParams.get('playlist')) {
      this.playlist = JSON.parse(url.searchParams.get('playlist'));
    }
    if (url.searchParams.get('website')) {
      this.website = JSON.parse(url.searchParams.get('website'));
    }
    // if (url.searchParams.get('user')) {
    //   this.userInReq = JSON.parse(url.searchParams.get('user'));
    // }
    if (url.searchParams.get('merchant_id')) {
      this.url_merchant_id = url.searchParams.get('merchant_id');
    }
    if (url.searchParams.get('code')) {
      this.url_code = url.searchParams.get('code');
    }
    if (url.searchParams.get('client_id')) {
      this.url_client_id = url.searchParams.get('client_id');
    }

    this.deleteQueryParameterFromCurrentRoute();

    return url;
  }

  processLoggedInUrlUser(url) {
    // if (this.userInReq) {
    //   this.currentUser = JSON.parse(this._AESEncryptDecryptService.decrypt(this.userInReq));
    //   if (this.currentUser) {
    //     localStorage.setItem(Constants.CURRENT_USER, JSON.stringify(this.userInReq));
    //     this.routeToProperComponent();
    //   }
    // }
  }

  performCloverAuthentication() {
      this.authenticationService.cloverLogin(this.url_merchant_id, this.url_client_id, this.url_code)
        .subscribe(
          data => {
            if (this.currentUser) {
              this.routeToProperComponent();
            } else if (!this.currentUser && data) {
              this.currentUser = data;
              this.selectedRole = this.currentUser.role[0];
              this.routeToProperComponent();
            } else {
              this.router.navigate([''], {queryParamsHandling: 'preserve'});
            }
          },
          error => {
            console.error(error);
            this.showNotification('error', error);
            this.router.navigate([''], {queryParamsHandling: 'preserve'});
          });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  scanToPayRouter(orderId, oldUser) {
    return this.router.navigate(['/payment-kiosk/paymentScreen',
        {
          tableUuid: oldUser.firstName ? oldUser.firstName : oldUser.username,
          tableName: oldUser.firstName ? oldUser.firstName : oldUser.username,
          orderId: orderId,
          scanToPay: true,
          lineItemStatus: 'CreditCardPay',
          disablePrint: true
        }]);
  }

  scanToJukeboxPay(playList, user) {
    return this.router.navigate(['/payment-kiosk/paymentScreen',
        {
          tableUuid: user.firstName ? user.firstName : user.username,
          tableName: user.firstName ? user.firstName : user.username,
          playlist: playList,
          scanToPay: true,
          lineItemStatus: 'CreditCardPay',
          disablePrint: true
        }]);
  }

  private routeToProperComponent() {
    if (this.currentUser.role.length > 1) {
      this.router.navigate(['/select-role']);
    } else if ((this.selectedRole === 'Admin'  && !this.currentUser.dashboardDisabled)) {
      this.router.navigate(['/sidebar/admin/dashboard']);
    } else if (this.selectedRole === 'Manager' || this.selectedRole === 'Server' || this.currentUser.dashboardDisabled) {
      this.router.navigate(['sidebar/admin/waiterMenu/serverOrder']).then(() => {
        // window.location.reload();
      });
    } else if (this.selectedRole === 'Table') {
      this.router.navigate(['/sidebar/order']).then(() => {
        // window.location.reload();
      });
    } else if (this.selectedRole === 'Rewards') {
      this.router.navigate(['/rewards'])
          .then(() => {
            // window.location.reload();
          });
    } else if (this.selectedRole === 'PaymentsDisplay') {
      this.router.navigate(['/paymentUser/paymentUser']).then(() => {
        // window.location.reload();
      });
    } else if (this.selectedRole === 'OrdersDisplay') {
      this.router.navigate(['/orderUser/orderUser']).then(() => {
        window.location.reload();
      });
    } else if (this.selectedRole === 'Kiosk') {
      this.router.navigate(['/kiosk']).then(() => {
        // window.location.reload();
      });
    } else if (this.selectedRole === 'QROrder') {
      this.router.navigate(['/QROrder']).then(() => {
        // window.location.reload();
      });
    } else {
      this.router.navigate(['/home'], {queryParamsHandling: 'preserve'}).then(() => {
        // window.location.reload();
      });
    }
  }

  deleteQueryParameterFromCurrentRoute() {
    // this.location.replaceState('./order');
    this.location.replaceState(window.location.href.split('?')[0].split(window.location.href.split('/')[2])[1]);
  }
}
